import React from 'react'
import { useUserState, useUserDispatch } from './context/user'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import AuthWorkflow from './brand-new/auth-workflow'
import AuthCallback from './brand-new/auth-callback'
import PublicDataclipContainer from 'client/containers/PublicDataclipContainer'
import DHC from './brand-new/dhc'

function App() {
  const { user, impersonatedUser } = useUserState()
  const dispatch = useUserDispatch()

  if (user) {
    return <DHC user={user} impersonatedUser={impersonatedUser} />
  } else {
    return (
      <Router>
        <Switch>
          <Route
            exact
            path="/auth/heroku/callback"
            render={props => <AuthCallback {...props} dispatch={dispatch} />}
          />
          <Route
            exact
            path="/dataclips/:slug(\w{28})"
            component={PublicDataclipContainer}
          />
          <Route component={AuthWorkflow} />
        </Switch>
      </Router>
    )
  }
}

export default App
