import React from 'react'
import PropTypes from 'prop-types'

import { HKBarChart, HKLineChart } from '@heroku/react-hk-components'
import analytics from 'client/helpers/analytics'

export default class DataclipVisualization extends React.Component {
  static propTypes = {
    data: PropTypes.array.isRequired,
    height: PropTypes.number,
    labels: PropTypes.array,
    type: PropTypes.oneOf(['line', 'bar']),
    width: PropTypes.number
  }

  static defaultProps = {
    width: 400,
    height: 300
  }

  componentDidMount () {
    analytics.track('Dataclip Chart Viewed')
  }

  render () {
    const { height, width } = this.props
    return this.props.type === 'line' ? (
      <HKLineChart
        {...this.props}
        height={height}
        width={width}
      />
    ) : (
      <HKBarChart {...this.props} height={height} width={width} />
    )
  }
}
