/*

heuristics on what, which, how to render the data visualizations for dataclips

* @param  {Array} data                The dataclip result object
* @return {Object}                    props of data visualization
{ type, data, labels }

*/

export default function getVisualizationProps ({ fields, type_names, type, values } = {}) {
  if (!isColumnCountSuitable(fields.length) || !isRowCountSuitable(values.length)) {
    return false
  }

  const graphType = whichGraph(type_names, type, values)
  if (graphType === null) {
    return false
  }

  const yValuesIndex = getIndexValues(type_names, isNumeric)
  if (!yValuesIndex || yValuesIndex.length === 0) {
    return false
  }

  let data
  switch (graphType) {
    case 'line':
      const xValueIndex = type_names.findIndex(isTimestamp)

      data = values.map((d, i) => {
        const timeStamp = d[xValueIndex]
        const values = yValuesIndex.map(v => d[v])
        return [timeStamp, values]
      })
      break

    case 'bar':
      data = values.map(rowValue => rowValue.filter((v, i) => yValuesIndex.includes(i)))
  }

  const labels = getLabels(fields, yValuesIndex)

  return { data, labels, type: graphType }
}

export function isNumeric (postgresType) {
  return [
    'smallint', 'integer', 'bigint', 'real', 'double precision', 'numeric', 'float'
  ].includes(postgresType)
}

export function isTimestamp (postgresType) {
  return [ 'date', 'timestamp with time zone', 'timestamp without time zone' ].includes(postgresType)
}

export function getLabels (names, indexes) {
  return names.filter((d, i) => indexes.includes(i))
}

function isRowCountSuitable (length) {
  return length >= 10 && length <= 1000 // abritrary max/min limit
}

function isColumnCountSuitable (length) {
  return length > 1
}

// returns an array of index values that meet specific condition
function getIndexValues (values, condition) {
  return values.reduce((r, d, i) => {
    if (condition(d)) {
      r.push(i)
    }
    return r
  }, [])
}

// returns the type of graph that should be displayed
function whichGraph (typeNames, type, values) {
  if (typeNames.some(isTimestamp) && typeNames.some(isNumeric)) {
    return 'line'
  }

  if (typeNames.some(isNumeric)) {
    return 'bar'
  }

  return null
}
