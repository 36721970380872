import React from 'react'
import axios from 'axios'
import moment from 'moment'
import uuid from 'uuid'

import 'client/styles/main.scss'
import 'client/styles/purple_hacks.scss'
import logger from 'client/helpers/logger'
import { DashboardStorage, initGlostick } from '../utils'

function format(...args) {
  if (args.length === 1 && args[0] && args[0].ts && args[0].payload) {
    let event = args[0]
    return [event.ts, ...event.payload]
  } else {
    let ts = moment.utc().format('YYYY-MM-DDTHH:mm:ss.SSSZ')
    return [ts, ...args]
  }
}

function configureLogger() {
  logger.configure('adapter', {
    log(...args) {
      console.log(...format(...args))
    },
    debug(...args) {
      console.debug(...format(...args))
    },
    error(...args) {
      console.error(...format(...args))
    }
  })
}

function setWindowVariables({ user, options, service }) {
  window.clientServerAPIContractVersion = options.clientServerAPIContractVersion
  window.clientVersionHash = options.herokuSlugCommit
  window.sendAnalytics = options.sendAnalytics
  window.user = user
  window.service = service
}

function configureAxios({ options, user, impersonatedUser }) {
  const authToken = impersonatedUser
    ? impersonatedUser.sudoToken
    : user.accessToken

  if (impersonatedUser) {
    axios.defaults.headers.common['X-Heroku-Sudo'] = true
    axios.defaults.headers.common['X-Heroku-Sudo-Reason'] =
      impersonatedUser.sudoReason
    axios.defaults.headers.common['X-Heroku-Sudo-User'] = impersonatedUser.email
  }

  axios.defaults.headers.common['Client-Server-Api-Contract-Version'] =
    options.clientServerAPIContractVersion
  axios.defaults.headers.common['Authorization'] = `Bearer ${authToken}`
  axios.defaults.headers.common['X-Refresh-Token'] = user.refreshToken
  axios.defaults.baseURL = process.env.REACT_APP_HEROKUDATA_BACKEND
}

function configureSessionStorage() {
  DashboardStorage.setSessionItem('herokudataTabId', uuid.v4())
}

function configureRollbar(user) {
  if (window.Rollbar) {
    window.Rollbar.configure({
      payload: { person: { id: user.id } }
    })
  }
}

function bootstrap({ user, options, impersonatedUser, service }) {
  configureRollbar(user)
  configureLogger()
  configureAxios({ options, user, impersonatedUser })
  initGlostick(user, impersonatedUser)
  setWindowVariables({ user, options, service })
  configureSessionStorage()
}

function BootstrapClient({
  children,
  user,
  impersonatedUser,
  options,
  service
}) {
  React.useEffect(() => {
    bootstrap({ user, options, impersonatedUser, service })
  }, [user])

  return children
}

export default BootstrapClient
