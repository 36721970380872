let options = {
  defaultAdapter: console,
  adapter: null
}

function configure (name, value) {
  options[name] = value
}

function adapter () {
  return options.adapter || options.defaultAdapter
}

export function debug (...args) {
  adapter().debug(...args)
}

export function log (...args) {
  adapter().log(...args)
}

export function error (...args) {
  adapter().error(...args)
}

const logger = {log, error, debug, configure, adapter}

export default logger
