import React from 'react'
import Operation from './Operation'

function getDisplayName (WrappedComponent) {
  return WrappedComponent.displayName || WrappedComponent.name || 'Component'
}

export default function withOperation (Component, opProps) {
  const fn = (props) => {
    return (
      <Operation {...opProps}>
        {(operation) => (
          <Component {...props} operation={operation} />
        )}
      </Operation>
    )
  }
  fn.displayName = `Operation(${getDisplayName(Component)})`
  return fn
}
