import Rollbar from 'rollbar'

export default function initializeRollbar() {
  const ENABLE_CLIENT_ROLLBAR = !!process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN
  if (ENABLE_CLIENT_ROLLBAR) {
    window.Rollbar = new Rollbar({
      autoInstrument: false,
      scrubFields: [
        'access_token',
        'api_key',
        'authenticity_token',
        'body.trace_chain.0.extra.cookies',
        'body.trace_chain.0.extra.msg',
        'body.trace_chain.0.extra.session.csrf.token',
        'bouncer.refresh_token',
        'bouncer.token',
        'code',
        'confirm_password',
        'heroku_oauth_token',
        'heroku_session_nonce',
        'heroku_user_session',
        'logplexUrl',
        'oauth_token',
        'old_secret',
        'passwd',
        'password',
        'password_confirmation',
        'postgres_session_nonce',
        'request.cookie',
        'request.cookies',
        'request.cookies.signup-sso-session',
        'request.params._csrf',
        'request.session.csrf',
        'request.session.csrf.token',
        'request.session._csrf',
        'request.session._csrf_token',
        'secret',
        'secret_token',
        'state',
        'sudo_oauth_token',
        'super_user_session_secret',
        'token',
        'user_session_secret',
        'www-sso-session'
      ],
      accessToken: process.env.REACT_APP_ROLLBAR_CLIENT_TOKEN,
      captureUncaught: true,
      captureUnhandledRejections: true,
      checkIgnore: function(isUncaught, args, payload) {
        try {
          var toIgnore = ['NetworkError']
          var errorClass = payload.data.body.trace.exception.class
          var ignore = toIgnore.indexOf(errorClass) >= 0

          return ignore
        } catch (e) {
          // in case something goes wrong, just
          // track the exception.
          return false
        }
      },
      payload: {
        environment: process.env.REACT_APP_ROLLBAR_CLIENT_ENVIRONMENT,
        client: {
          javascript: {
            source_map_enabled: true,
            guess_uncaught_frames: true
          }
        }
      }
    })
  } else {
    console.log('🚫 Rollbar is now disabled in development mode')

    window.Rollbar = {
      configure: () => {},
      error(...args) {
        console.error(...args)
      },
      warn(...args) {
        console.warn(...args)
      }
    }
  }
}
