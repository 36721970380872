import Immutable from 'immutable'
import { handleActions } from 'redux-actions'
import _ from 'lodash'

import logger from 'client/helpers/logger'

export const dataclipActions = {
  DATACLIPS_SET: 'DATACLIPS_SET',
  DATACLIPS_MERGE: 'DATACLIPS_MERGE',
  DATACLIPS_DELETE: 'DATACLIPS_DELETE',
  DATACLIPS_SYNC: 'DATACLIPS_SYNC',
  DATACLIPS_SHARE: 'DATACLIPS_SHARE',
  DATACLIPS_UNSHARE: 'DATACLIPS_UNSHARE',
}

export const dataclipStatuses = {
  DRAFT: 'draft',
  PUBLISHED: 'published',
}

const lookupPredicate = function (state, action) {
  return (d) => {
    let clip
    // We may have one of three things when looking up a dataclip:
    // a change id, an actual id, or a slug. We need to be able to
    // find a clip using any one of these, in this order: a change
    // for an inflight clip creation, an id for most use cases, and
    // a slug for looking up directly from the URL.
    if (action.payload._change && action.payload._change.id) {
      clip = action.payload._change.id === d.getIn(['_change', 'id'])
    }
    if (!clip && action.payload.id) {
      clip = action.payload.id === d.get('id')
    }
    if (!clip && action.payload.slug) {
      clip = action.payload.slug === d.get('slug')
    }
    return clip
  }
}

const reducer = handleActions({
  [dataclipActions.DATACLIPS_SET]: function (state, action) {
    const index = state.findIndex(lookupPredicate(state, action))
    return state.update(
      index === -1 ? state.size : index, // upsert
      Immutable.Map(), // if upsert, this is the value that is passed into the following updater
      (d) => Immutable.fromJS(action.payload)
    )
  },

  [dataclipActions.DATACLIPS_MERGE]: function (state, action) {
    const index = state.findIndex(lookupPredicate(state, action))
    return state.update(
      index === -1 ? state.size : index, // upsert
      Immutable.Map(), // if upsert, this is the value that is passed into the following updater
      (d) => d.merge(Immutable.fromJS(action.payload))
    )
  },

  [dataclipActions.DATACLIPS_DELETE]: function (state, action) {
    const index = state.findIndex(lookupPredicate(state, action))
    return index === -1 ? state : state.delete(index)
  },

  [dataclipActions.DATACLIPS_SYNC]: function (state, action) {
    const newIDs = action.payload.map((record) => record.id)
    const oldIDs = state.map((d) => d.get('id')).toArray()
    const idsToRemove = _.difference(oldIDs, newIDs)
    const idsToAdd = _.difference(newIDs, oldIDs)
    const idsToMerge = _.intersection(oldIDs, newIDs)

    logger.debug(`sync dataclips: +${idsToAdd.length} / -${idsToRemove.length} / <>${idsToMerge.length}`)

    if (idsToRemove.length === 0 && idsToAdd.length === 0 && idsToMerge.length === 0) {
      return state // no change
    } else {
      // build an Immutable Map of all clips by their id
      const mergeMap = Immutable.fromJS(
        action.payload.reduce((map, clip) => {
          map[clip.id] = clip
          return map
        }, {}))

      // build an Immutable List of clips that are not present in state and should be added
      const addList = Immutable.fromJS(
        action.payload.filter((clip) => idsToAdd.includes(clip.id)))

      return state
        // remove all the ones that are no longer with us
        .filter((clip) => newIDs.includes(clip.get('id')))
        // merge all the ones that exist in both
        .map((clip) => clip.merge(mergeMap.get(clip.get('id'))))
        // add the ones that are new
        .concat(addList)
    }
  },

  [dataclipActions.DATACLIPS_SHARE]: function (state, action) {
    const { clipShare, shareType } = action.payload
    const index = state.findIndex((d) => d.get('id') === clipShare.clip_id)
    return state.updateIn(
      [index, `${shareType}_shares`],
      Immutable.List(),
      list => list.push(Immutable.fromJS(clipShare))
    )
  },

  [dataclipActions.DATACLIPS_UNSHARE]: function (state, action) {
    const { clipId, clipShareId, shareType } = action.payload
    const index = state.findIndex((d) => d.get('id') === clipId)

    return state.updateIn(
      [index, `${shareType}_shares`],
      Immutable.List(),
      list => list.filter((clipShare) => clipShare.get('id') !== clipShareId)
    )
  }
}, Immutable.List())

export default reducer
