import React from 'react'
import PropTypes from 'prop-types'
import Decimal from 'decimal.js'

export default class DataclipResultCell extends React.PureComponent {
  static propTypes = {
    className: PropTypes.string,
    name: PropTypes.string,
    type: PropTypes.any,
    value: PropTypes.any,
  }

  isUrl (value) {
    if (!value.match(/^https?:\/\//)) {
      return false
    }

    try {
      /* eslint-disable no-new */
      new URL(value)
      return true
    } catch (e) {
      return false
    }
  }

  formatThousands (value) {
    const [ intPart, fractPart ] = value.split('.')
    const thousandsSeperated = intPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    const formatted = thousandsSeperated + (fractPart ? '.' + fractPart : '')
    return formatted
  }

  isNull (value) {
    return value === null || value === undefined
  }

  formatNull (alignment = 'tl') {
    return <span className={`nowrap gray ttu tracked f6 ${alignment}`}>null</span>
  }

  formatBoolean (value) {
    if (this.isNull(value)) {
      return this.formatNull()
    }

    return <span className="nowrap dark-gray code f6 tl">{String(value)}</span>
  }

  formatNumber (value, type, name) {
    if (this.isNull(value)) {
      return this.formatNull('tr')
    }

    const isIdColumn = name.match(/^id$/i) || name.match(/_(id|fk|pk)$/i)
    const number = new Decimal(value).toFixed()

    let formatted = isIdColumn
      ? value
      : (<React.Fragment>
          {
            this.formatThousands(number)
              .split(',')
              .map((c, idx) => <span className='mr--2' key={idx}>{c}</span>)
          }
        </React.Fragment>)
    return <span className="nowrap dark-gray code f6 tr">{formatted}</span>
  }

  formatString (value) {
    if (this.isNull(value)) {
      return this.formatNull()
    }

    if (value === '') {
      return <span className='nowrap gray ttu tracked f6'>empty</span>
    } else if (this.isUrl(value)) {
      return (<span className="nowrap dark-gray tl">
        <a target='_blank' rel='noopener noreferrer' className="blue" href={value}>{value}</a>
      </span>)
    } else {
      return <span className="lh-copy dark-gray tl">{value}</span>
    }
  }

  formatTimestamp (value, type) {
    if (this.isNull(value)) {
      return this.formatNull()
    }

    // TODO: strip tz offset from 'timestamp without timezone' timestamp
    const formatted = value
    return <span className="nowrap dark-gray code f6 tl">{formatted}</span>
  }

  formatObject (value, type) {
    return (
      <span className="nowrap dark-gray f7 tl">
        <pre className="code mv0 pa2 h4 overflow-y-auto ba b--silver br2">{JSON.stringify(value, null, 2)}</pre>
      </span>
    )
  }

  format (value, type, name) {
    switch (type) {
      case 'text':
      case 'character varying':
      case 'unknown':
        return this.formatString(value)
      case 'boolean':
        return this.formatBoolean(value)
      case 'smallint':
      case 'integer':
      case 'bigint':
      case 'real':
      case 'double precision':
      case 'float':
      case 'numeric':
        return this.formatNumber(value, type, name)
      case 'date':
      case 'timestamp without time zone':
      case 'timestamp with time zone':
        return this.formatTimestamp(value, type)
      case 'hstore':
      case 'json':
        return this.formatObject(value, type)
      default:
        return this.isNull(value)
          ? this.formatNull()
          : this.formatString(value.toString())
    }
  }

  render () {
    const { value, type, name } = this.props
    return this.format(value, type, name)
  }
}
