import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import classnames from 'classnames'
import ContainerDimensions from 'react-container-dimensions'
import { HKTable, HKTableHeader } from '@heroku/react-hk-components'

import DataclipResultCell from 'client/components/DataclipResultCell'
import DataclipResultTableEmptyState from 'client/components/DataclipResultTableEmptyState'

const PAGINATION_THRESHOLD = 500

export default class DataclipResultTable extends React.PureComponent {
  static propTypes = {
    data: PropTypes.instanceOf(Immutable.Map),
  }

  state = {
    sort: {
      id: null
    }
  }

  isNumeric (postgresType) {
    return [ 'smallint', 'integer', 'bigint', 'real', 'double precision', 'numeric', 'float' ].includes(postgresType)
  }

  handleSortChange = (columns) => {
    const [sort] = columns
    this.setState({
      sort
    })
  }

  render () {
    const { data } = this.props
    const { sort } = this.state

    if (data.get('values').size === 0) {
      return <DataclipResultTableEmptyState />
    }

    const columns = data.get('fields').map((title, fieldIdx) => {
      return {
        id: fieldIdx.toString(),
        title,
        minWidth: 200,
        maxWidth: 250,
        accessor: d => {
          return d[fieldIdx]
        },
        style: {
          padding: 12,
          whiteSpace: 'normal',
          justifyContent: this.isNumeric(data.get('type_names').get(fieldIdx)) ? 'flex-end' : 'flex-start'
        },
        Header: ({cellData, column}) => {
          const alignmentClass = this.isNumeric(data.get('type_names').get(fieldIdx)) ? 'justify-end' : ''
          return <HKTableHeader className={alignmentClass} label={title} id={column.id} sort={sort} />
        },
        Cell: props => {
          const value = props.original[props.column.id]
          const type = data.get('type_names').get(props.column.id)
          const title = props.column.title
          return (
            <DataclipResultCell
              value={value}
              type={type}
              name={title}
            />
          )
        }
      }
    })

    const numRows = data.get('values').size
    const showPagination = numRows > PAGINATION_THRESHOLD
    const pageSize = showPagination ? PAGINATION_THRESHOLD : numRows

    return (
      <ContainerDimensions>
        {
          ({height}) => {
            const tableHeight = `${Math.max(300, height)}px`
            return (
              <HKTable
                className={classnames('bg-white shadow-outer-1 br1', {'has-pagination': showPagination})}
                data={data.get('values').toJS()}
                columns={columns.toJS()}
                height={tableHeight}
                minRows={0}
                onSortedChange={this.handleSortChange}
                showPagination={showPagination}
                pageSize={pageSize}>
              </HKTable>
            )
          }
        }
      </ContainerDimensions>
    )
  }
}
