import React from 'react'
import PropTypes from 'prop-types'

import { isEqual, isArray } from 'lodash'

import logger from 'client/helpers/logger'
import asyncStatus from '.'

export const CanceledError = new Error('generator was canceled')

const OperationContext = React.createContext({ services: {} })

/**

  Operation provides a way for Components to interact easily with generator
  functions, and use those as a means of handling asynchonous user interaction
  flows. The Operation accepts a generator function as a prop, and can run
  resulting generators to completion when triggered, providing feedback by
  invoking its `children` render prop with arguments corresponding to its
  progress.

  An Operation may be triggered either automatically, when `autorun` is set to
  true, or via the `trigger` parameter passed down to the `children` function,
  when it is false. When `autorun` is set, arguments to the generator are taken
  from the `arguments` prop. When `trigger` is called, it must be called with
  the arguments to use (and the `arguments` prop must be undefined).

  Generators may yield effects to be resolved (asynchronously) by the Operation.
  Each effect describes the action to take; the Operation resolves the effect,
  and yields the result back to the generator.

  An effect may also yield `state` describing arbitrary generator progress; this
  will be passed on to the `children` function. When a generator completes, its
  return value is the final `state`.

  An object of keyword arguments is passed to the `children` function:

   `state`    - arbitrary state produced by an effect or generator
   `status`   - the asyncStatus of the generator: it can be `undefined` (if
                the generator has not yet started or has expired), `PENDING`
                (if the generator is currently running), `FULFILLED` (if it
                has completed), or `REJECTED` (if it threw an Error).
    `error`   - if a generator is `REJECTED`, this is the Error it threw;
                otherwise `undefined`.
    `trigger` - if the generator can currently be triggered manually
                (autorun false, arguments unset, not running), this is the
                trigger function, described above. This is undefined otherwise.
    `clear`   - if the generator is not running, this will clear its state,
                status, and error information from the Operation state.
                Undefined otherwise.

  When another operation is triggered while the last operation is still in
  progress, the previous operation is cancelled. When the Operation unmounts,
  any in-flight generator is cancelled. Cancellation occurs by throwing a
  CancelError (exported above) into the generator.

  An Operation may expire with `expireAfter`; if set, the operation will expire
  that many milliseconds after it completes. When an operation expires, it
  is cleared from the component's state. If `autorun` is also set, the operation
  will immediately re-run automatically.

*/
export default class Operation extends React.PureComponent {
  static propTypes = {
    arguments: PropTypes.array,
    autorun: PropTypes.bool, // whether to automatically trigger on autorun or if generator/arguments changes
    children: PropTypes.func.isRequired, // render with { state, status, error }
    expireAfter: PropTypes.number,
    generator: PropTypes.func.isRequired,
  }

  static defaultProps = {
    autorun: false,
  }

  render () {
    return (
      <OperationContext.Consumer>
        {({services}) => (
            <OperationRunner {...this.props} services={services} />
        )}
      </OperationContext.Consumer>
    )
  }
}

/**

  OperationConfig allows context-driven configuration of Operations with
  services they may rely on. The services object can provide arbitrary
  services for effects to depend on, keyed by name. The Operation will resolve
  the effects using these services.

*/
export class OperationConfig extends React.Component {
  static propTypes = {
    children: PropTypes.node,
    services: PropTypes.object.isRequired,
  }

  render () {
    const { services } = this.props
    return (
      <OperationContext.Provider value={{ services }}>
        {this.props.children}
      </OperationContext.Provider>
    )
  }
}

class OperationRunner extends React.Component {
  static propTypes = {
    arguments: PropTypes.array,
    autorun: PropTypes.bool, // whether to automatically trigger on mount or if generator/arguments changes
    children: PropTypes.func.isRequired, // rendered with { state, status, error }
    expireAfter: PropTypes.number, // if set, clear status after this many milliseconds; if also autorun, rerun
    generator: PropTypes.func.isRequired,
    services: PropTypes.object.isRequired,
  }

  static defaultProps = {
    autorun: false,
  }

  state = {}

  componentDidMount () {
    if (this.props.autorun) {
      this.run(this.props.arguments)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (this.props.autorun && (
      !prevProps.autorun ||
      !isEqual(this.props.arguments, prevProps.arguments) ||
      !isEqual(this.props.services, prevProps.services) ||
      (!this.state.status && prevState.status) // status cleared means expired, so refresh if autorun
    )) {
      this.run(this.props.arguments)
    }
  }

  componentWillUnmount () {
    // React recommends against this because the right solution is to clean up when
    // unmounting, but our cleanup is necessarily asynchronous: if the generator we
    // are running is asynchronous, we cannot cancel it synchronously
    this.unmounting = true
    clearTimeout(this.expireTimeout)
    this.cancelGenerator(this.state.currGen)
  }

  resolveArgs (args) {
    if (isArray(args)) {
      return args
    } else {
      return []
    }
  }

  async cancelGenerator (gen) {
    if (!gen || gen.canceled || gen.done) {
      // nothing to do
      return
    }

    gen.canceled = true
    let res
    try {
      res = await gen.throw(CanceledError)
    } catch (e) {
      if (e !== CanceledError) {
        logger.debug('canceled generator threw error', e)
      }
    } finally {
      if (res && !res.done) {
        logger.debug('canceled generator not done')
      }
    }
  }

  // Run our generator with specified args, handling concurrency (i.e.,
  // currently just cancelling any previous generator), and updating
  // our state as the generator runs, completes, or fails.
  async run (args) {
    clearTimeout(this.expireTimeout)
    const { generator } = this.props
    const actualArgs = this.resolveArgs(args)
    let gen
    try {
      // For now, just cancel the previous generator; in the
      // future we may want more concurrency options like enqueue.
      this.cancelGenerator(this.state.currGen)

      gen = generator(...actualArgs)
      this.setState({ state: undefined, status: asyncStatus.PENDING, currGen: gen })
      const result = await this.drive(gen)
      if (!gen.canceled) {
        // Flag the generator itself as done here, rather than rely on
        // setState, because the latter is asynchronous and we don't want
        // to try cancelling a generator that has already finished if it
        // did so right before unmounting or running a new generator.
        gen.done = true
        this.setState({
          state: result,
          status: asyncStatus.FULFILLED,
          lastStatus: asyncStatus.FULFILLED,
        })
      }
    } catch (e) {
      if (!gen.canceled) {
        logger.debug(`generator ${generator.name}(${actualArgs.join(', ')}) failed:`, e)
        this.setState({
          error: e,
          state: undefined,
          status: asyncStatus.REJECTED,
          lastStatus: asyncStatus.REJECTED,
        })
      }
    } finally {
      if (!this.unmounting && !gen.canceled) {
        let { expireAfter } = this.props
        if (expireAfter) {
          this.expireTimeout = setTimeout(() => {
            this.setState((state) => {
              // don't clobber if another generator started in the meantime
              if (state.currGen === gen) {
                return { error: undefined, state: undefined, status: undefined, currGen: undefined }
              }
            })
          }, expireAfter)
        } else {
          this.setState({ currGen: undefined })
        }
      }
    }
  }

  // Run the generator to completion
  async drive (gen) {
    let next = await gen.next()
    while (!next.done) {
      let resolved = await this.takeStep(next.value)
      next = await gen.next(resolved)
    }
    // N.B.: the final .value (i.e., when .done is true: the return
    // value of the generator) is not resolved as a step but returned
    // directly
    return next.value
  }

  // Take a single step in running the generator; resolve the value
  // according to our configured services, and update our state if necessary
  async takeStep (effect) {
    const progress = await this.resolve(effect)
    if (progress && progress.state) {
      this.setState({ state: progress.state })
    }
    return progress ? progress.result : undefined
  }

  // Resolve a single effect according to configured services
  resolve = (effect) => {
    return effect.fn(this.props.services, ...effect.args)
  }

  // Manually trigger the generator with specified args
  trigger = (...args) => {
    this.run(args)
  }

  clear = () => {
    this.setState(({ status }) => {
      if (status === asyncStatus.FULFILLED) {
        return { status: undefined, state: undefined, error: undefined }
      }
    })
  }

  render () {
    const { state, status, lastStatus, error } = this.state
    const passTrigger = !this.props.autorun && this.props.arguments === undefined && status !== asyncStatus.PENDING
    const passClear = status === asyncStatus.FULFILLED
    return this.props.children({
      state,
      status,
      lastStatus,
      error,
      trigger: passTrigger ? this.trigger : undefined,
      clear: passClear ? this.clear : undefined,
    })
  }
}
