import logger from 'client/helpers/logger'

/**

 Effects are functions that take arbitrary arguments and return
 a description of an action to take, an object with two fields:

   `fn` - the (possibly async) function to invoke when resolving the effect
   `args` - the arguments the effect was invoked with

 When the effect is resolved, the function it returns is called
 with `args`, the arguments the effect was called with, prepended
 with `services`, an Object of arbitrary services the action may
 depend on. If the service an effect depends on is absent, it should
 log an error and do nothing. The return value of `fn` should either
 be undefined, or an object with two optional fields:

   `result` - the result of the effect, to feed back to the generator
   `state`  - the state change due to resolving the effect, if any

 State is passed to children of Operation as the generator progresses,
 and may consist of arbitrary objects. If no state is returned, the
 previous state is reused.

*/

// A helper function for building effects
export function effect (fn) {
  return (...args) => {
    return {
      args,
      fn,
    }
  }
}

// Calls the provided method, with the provided receiver as `this`,
// passing the arguments provided
export const call = effect(async (services, [receiver, method], ...args) => {
  const result = await method.apply(receiver, args)
  return { result }
})

// Uses the `dispatch` service to dispatch an action
export const put = effect((services, action) => {
  if (!services.dispatch) {
    logger.error('no dispatch service available for', action)
    return
  }

  services.dispatch(action)
})

export const all = effect(async (services, effects) => {
  const allResults = await Promise.all(effects.map((e) => e.fn(services, ...e.args)))
  return allResults.reduce((combinedResult, effectResult) => {
    combinedResult.state.push(effectResult.state)
    combinedResult.result.push(effectResult.result)
    return combinedResult
  }, { state: [], result: [] })
})

// Waits for the specified number of milliseconds
export const delay = effect((services, delayMs) => {
  return new Promise((resolve, reject) => {
      setTimeout(resolve, delayMs)
  })
})

// Updates the generator state yielded to the consuming component
export const state = effect((services, state) => {
  return { state }
})
