import React from 'react'
import PropTypes from 'prop-types'

import uuid from 'uuid'
import ReactTooltip from 'react-tooltip'

import './style.scss'

export default class Tooltip extends React.Component {
  static propTypes = {
    children: PropTypes.node.isRequired,
    content: PropTypes.node.isRequired
  }

  render () {
    const { children, content } = this.props
    const tipId = uuid.v4()

    return (<div>
      <div data-tip data-for={tipId}>
        {children}
      </div>
      <ReactTooltip className='tooltip lh-title' id={tipId} place='top' type='dark' effect='solid'>
        {content}
      </ReactTooltip>
    </div>)
  }
}
