import React from 'react'
import Immutable from 'immutable'
import moment from 'moment'

import DataclipHeader from 'client/components/DataclipHeader'
import DataclipResultError from 'client/components/DataclipResultError'
import DataclipResultSection from 'client/components/DataclipResultSection'
import DataclipResultSpinner from 'client/components/DataclipResultSpinner'
import analytics from 'client/helpers/analytics'
import Operation from 'client/lib/operation/Operation'
import asyncState from 'client/constants/asyncState'
import fetchPublicClip from 'client/operations/fetchPublicClip'

import { initGlostick } from "../../utils"
import AuthWorkflow from '../../brand-new/auth-workflow';

const RenderPublicClip = ({status, state, error, slug}) => {
  const isLoading = status === asyncState.PENDING
  const isLoaded = status === asyncState.FULFILLED
  const isErrored = status === asyncState.REJECTED
  const notFound = error && error.response.status === 404

  React.useEffect(() => {
    if (isLoaded && window.Rollbar) {
      window.Rollbar.configure({
        payload: {
          public: true,
          clipId: state.getIn(['metadata', 'clip', 'id'])
        }
      })
    }
  }, [isLoaded, state])

  const getTitle = () => isLoaded ? state.getIn(['metadata', 'clip', 'title']) : ''

  const getResultSectionProps = () => {
    return {
      fetchingResults: false,
      hasNewResults: false,
      onRefreshResults: () => {},
      dataclip: Immutable.fromJS({
        ...state.getIn(['metadata', 'clip']),
        access_token: null,
        slug
      }),
      resultMetadata: addDuration(state.getIn(['metadata', 'clip', 'result'])),
      results: state.get('results'),
    }
  }
  
  return (
    <React.Fragment>
      <DataclipHeader loading={isLoading} title={getTitle()} isPublic={true} />
      {
        isLoading && <DataclipResultSpinner message="Loading results" />
      }
      {
        isLoaded && state.get('error') && <DataclipResultError errorMessage={state.get('error')} />
      }
      {
        isLoaded && state.get('results') && <DataclipResultSection {...getResultSectionProps()} />
      }
      {
        isErrored && notFound && <AuthWorkflow />
      }
      {
        isErrored && !notFound && <DataclipResultError errorMessage='An unexpected error occurred.' />
      }
    </React.Fragment>
  )
}

const addDuration = (metadata) => {
  let duration
  const finished = metadata.get('query_finished_at')
  const started = metadata.get('query_started_at')
  if (!finished || !started ) {
    duration = 0
  }
  duration = moment(finished).diff(moment(started))
  return metadata.set('duration', duration)
}

const PublicDataclipContainer = ({match}) => {
  const { slug } = match.params

  React.useEffect(() => {
    initGlostick({email: ''})
    analytics.track('Public Dataclip Page Viewed')
  }, [])

  return (
    <Operation generator={fetchPublicClip} autorun arguments={[slug]}>
      {
        (props) => <RenderPublicClip {...props} slug={slug} />
      }
    </Operation>
  )
}

export default PublicDataclipContainer
