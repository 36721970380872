import Immutable from 'immutable'
import axios from 'axios'

import { call } from 'client/lib/operation/effects'
import { DashboardStorage } from "../../utils"

function * fetchPublicClip (slug) {
  let payload = {
    metadata: null,
    error: null,
    results: null
  }

  const metadata = yield call(
    [axios, axios.get],
    `${process.env.REACT_APP_HEROKUDATA_BACKEND}/dataclips/${slug}`,
    {
      headers: {
        'Accept': 'application/json',
        'X-Heroku-Data-Tab': DashboardStorage.getSessionItem('herokudataTabId')
      }
    })

  payload.metadata = metadata.data

  const clipExecutionError = metadata.data.clip.result.error

  if (clipExecutionError) {
    payload.error = clipExecutionError
    return Immutable.fromJS(payload)
  }

  const resultsRes = yield call(
    [axios, axios.get],
    `${process.env.REACT_APP_HEROKUDATA_BACKEND}/dataclips/${slug}.json`,
    {
      headers: {
        'Accept': 'application/json',
        'X-Heroku-Data-Tab': DashboardStorage.getSessionItem('herokudataTabId')
      }
    }
  )

  payload.results = resultsRes
  return Immutable.fromJS(payload)
}

export default fetchPublicClip
