import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class ReadOnlyInput extends Component {
  static propTypes = {
    className: PropTypes.string,
    onSelect: PropTypes.func,
    value: PropTypes.string.isRequired
  }

  static defaultProps = {
    onSelect: () => {}
  }

  handleSelectContents = (event) => {
    event.target.select()
    this.props.onSelect()
  }

  render () {
    const { className, value } = this.props
    return (<input onClick={this.handleSelectContents} type='text' className={classnames('hk-input db w-100', className)} readOnly={true} value={value} />)
  }
}
