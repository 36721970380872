import axios from 'axios'
import { DashboardStorage } from '../utils'

const scrubPlatformHeaders = (data, headers) => {
  delete headers.common['X-Refresh-Token']
  delete headers.common['Client-Server-Api-Contract-Version']
  delete headers.common['CSRF-Token']
  return data
}

const createSudoToken = async (description, code, reason) => {
  return await axios.post(
    `${process.env.REACT_APP_PLATFORM_API_URL}/oauth/authorizations`,
    {
      description,
      skip_two_factor: true
    },
    {
      headers: {
        'X-Origin': window.location.origin,
        'Heroku-Two-Factor-Code': code,
        'X-Heroku-Sudo': true,
        'X-Heroku-Sudo-Reason': reason,
        Accept: 'application/vnd.heroku+json; version=3'
      },
      transformRequest: [
        ...axios.defaults.transformRequest,
        scrubPlatformHeaders
      ]
    }
  )
}

const assumeIdentity = async (email, code, reason) => {
  return await axios.get(`${process.env.REACT_APP_PLATFORM_API_URL}/account`, {
    headers: {
      Authorization: `Bearer ${code}`,
      'X-Origin': window.location.origin,
      'X-Heroku-Sudo': true,
      'X-Heroku-Sudo-User': email,
      'X-Heroku-Sudo-Reason': reason,
      Accept: 'application/vnd.heroku+json; version=3'
    },
    transformRequest: [...axios.defaults.transformRequest, scrubPlatformHeaders]
  })
}

const impersonateUser = async formValues => {
  const { email, code, reason } = formValues
  const description = `herokudata-frontend sudo: ${email}`

  try {
    const sudoTokenResponse = await createSudoToken(description, code, reason)
    const { token } = sudoTokenResponse.data.access_token

    const assumeIdentityResponse = await assumeIdentity(email, token, reason)
    const {
      id,
      name: assumedName,
      email: assumedEmail
    } = assumeIdentityResponse.data
    DashboardStorage.setSessionItem(
      'hk-sudo-data',
      JSON.stringify({
        isAssumed: true,
        sudoReason: reason,
        sudoToken: token,
        user_id: id,
        email: assumedEmail,
        name: assumedName
      })
    )
    window.location.assign('/')
  } catch (e) {
    throw e
  }
}

const whoAmI = async token => {
  return await axios.get(`${process.env.REACT_APP_HEROKUDATA_BACKEND}/whoami`, {
    headers: {
      Authorization: `Bearer ${token}`
    }
  })
}

const AuthService = {
  createSudoToken,
  assumeIdentity,
  impersonateUser,
  whoAmI
}

export default AuthService
