const clipFragment = `fragment clipFragment on Clip {
  id
  created_at
  creator {
    id
    email
  }
  edited_at
  slug
  title
  user_shares {
    id
    clip_id
    shared_by {
      id
      email
    }
    shared_with {
      id
      email
    }
  }
  team_shares {
    id
    clip_id
    shared_by {
      id
      email
    }
    shared_with {
      id
      name
    }
  }
  status
  team_id
  access_token
  public_slug
  public_slug_by
  detached
  datasource {
    id
    addon_id
    addon_name
    attachment_id
    attachment_name
    app_id
    app_name
  }
  versions(limit: 1) {
    id
    created_at
    sql
    url
    latest_result_checksum
    latest_result_at
    latest_result_size
    creator_id
    creator {
      email
    }
    result {
      id
      query_started_at
      query_finished_at
      error
      completed_at
      duration
    }
  }
  editable
}`

const instanceFragment = `fragment instanceFragment on ConnectEventsInstance {
  id
  created_at
  state
  auth_updated
  app_name
  app_id
  plan
  resource_name
  db_key
  schema_name
  sf_info {
    username
    organization_id
    environment
    domain
    api_version
  }
  schemas {
    id
    label
    details {
      id
      label
      fieldType
    }
  }
  streams {
    id
    created_at
    object_name
    topic_name
    state
  }
}`

const privatelinkFragment = `fragment privatelinkFragment on PostgresPrivatelink {
  app_name
  addon_name
  addon_uuid
  status
  service_name
  whitelisted_accounts {
    arn
    status
  }
  connections {
    endpoint_id
    hostname
    owner_arn
    status
  }
}`

module.exports = {
  fetchUser: `query FetchUser {
      user {
        id
        email
        last_sync
        feature_flags

        addons {
          id
          name
          plan
          service
          app_uuid
          app_name
          web_url
          role

          attachments {
            id
            name
            app_name
            app_uuid
            namespace
          }
        }
      }
    }`,

  fetchKafkaDetails: `query FetchKafkaDetails($addonUUID: ID!) {
      kafka (addon_uuid: $addonUUID) {
        name
        state {
          message
          waiting
          healthy
          degraded_topics
          degraded_brokers
          status
        }
        capabilities {
          supports_mixed_cleanup_policy
        }
        shared_cluster
        limits {
          minimum_replication
          maximum_replication
          minimum_retention_ms
          maximum_retention_ms
          max_partition_replica_count
          max_number_of_partitions_per_topic
          max_number_of_topics,
        }
        defaults {
          partition_count
        }
        messages_in_per_sec
        bytes_in_per_sec
        bytes_out_per_sec
        data_size
        topicnames
        version
        admin_topic_names
        topics {
          topic
          messages_in_per_second
          bytes_in_per_second
          bytes_out_per_second
          data_size
          partitions
          replication_factor
          retention_enabled
          retention_time_ms
          compaction_enabled
          status
          status_label
        }
        topic_prefix
      }
    }`,

  fetchCassandraDetails: `query FetchCassandraDetails($addonUUID: ID!) {
      cassandra (addon_uuid: $addonUUID) {
        name
        state {
          message
          waiting
          healthy
        }
        user_keyspace_disk_space_used
        connected_native_clients
        connected_thrift_clients
        tablesCount
        read_latency {...LatencyFragment}
        write_latency {...LatencyFragment}
        tables {
          name
          keyspace
          total_disk_space_used
          write_latency {...LatencyFragment}
          read_latency {...LatencyFragment}
        }
      }
    }
    fragment LatencyFragment on CassandraLatencyOverview {
      perc50
      perc95
      perc99
      rate
    }`,

  fetchRedisDetails: `query FetchRedisDetails($addonUUID: ID!) {
      redis (addon_uuid: $addonUUID) {
        created_at
        credentials {
          hostname
          password
          port
          user
        }
        maintenance
        maintenanceWindow
        maxMemory
        name
        plan
        port
        resource_url
        status
        timeout
        version
      }
    }`,

  fetchPostgresqlDetails: `query FetchPostgresDetails($addonUUID: ID!) {
      postgres (addon_uuid: $addonUUID) {
        name
        usesLegacyInfrastructure
        addon_id
        hobby
        spaces
        shielded
        maintenance
        maintenance_window
        data_size
        num_bytes
        rows
        rowsLimit
        rowsLimitComplianceMessage
        connections
        tables
        plan
        region
        pg_version
        status
        primary
        role
        created_at
        rollback
        rollbackDate
        waiting
        following
        isAvailable
        leader {
          addon_id
          name
        }
        credentials {
          user
          password
          hostname
          port
          database
        }
        credentials_list {
          uuid
          name
          state
          database
          host
          port
          credentials {
            user
            password
            state
          }
        }
        backups {
          uuid
          readable_name
          addon_uuid
          created_at
          updated_at
          started_at
          finished_at
          deleted_at
          purged_at
          canceled_at
          num
          succeeded
          processed_bytes
          source_bytes
          from_name
          from_type
          to_name
          to_type
        }
        followers {
          name
          addon_id
          hobby
          maintenance
          maintenance_window
          data_size
          connections
          tables
          plan
          pg_version
          status
          primary
          role
          created_at
          rollback
          isAvailable
        }
        expensiveQueries {
          query_id
          text
          observations {
            time
            time_spent
            calls
            average_time
            read_time
            write_time
          }
        }
        canManageAddons
        settings {
          name
          value
          default
        }
      }
    }`,

  createKafkaTopic: `mutation CreateKafkaTopic($addonUUID: ID!, $topic: String!, $partitions: Int!, $rf: Int!, $compaction: Boolean!, $retentionTime: Float) {
        createKafkaTopic (
        addon_uuid: $addonUUID
        topic: $topic
        partitions: $partitions
        replicationFactor: $rf
        compaction: $compaction
        retentionTime: $retentionTime
      )
    }`,

  updateKafkaTopic: `mutation UpdateKafkaTopic($addonUUID: ID!, $topic: String!, $rf: Int!, $compaction: Boolean!, $retentionTime: Float) {
      updateKafkaTopic (
        addon_uuid: $addonUUID
        topic: $topic
        replicationFactor: $rf
        compaction: $compaction
        retentionTime: $retentionTime
      )
  }`,

  deleteKafkaTopic: `mutation DeleteKafkaTopic($addonUUID: ID!, $topic: String!) {
      deleteKafkaTopic (addon_uuid: $addonUUID, topic: $topic)
    }`,

  createPostgresBackup: `mutation CreatePostgresBackup($addonUUID: ID!) {
      createPostgresBackup(addon_uuid: $addonUUID) {
        uuid
        readable_name
        addon_uuid
        created_at
        updated_at
        started_at
        finished_at
        deleted_at
        purged_at
        canceled_at
        num
        succeeded
        processed_bytes
        source_bytes
        from_name
        from_type
        to_name
        to_type
      }
    }`,

  deletePostgresBackup: `mutation DeletePostgresBackup($addonUUID: ID!, $backupId: String!) {
      deletePostgresBackup(addon_uuid: $addonUUID, backup_id: $backupId) {
        uuid
        readable_name
        addon_uuid
        created_at
        updated_at
        started_at
        finished_at
        deleted_at
        purged_at
        canceled_at
        num
        succeeded
        processed_bytes
        source_bytes
        from_name
        from_type
        to_name
        to_type
      }
    }`,

  generatePostgresBackupUrl: `mutation GeneratePostgresBackupUrl($addonUUID: ID!, $backupId: String!) {
      generatePostgresBackupUrl(addon_uuid: $addonUUID, backup_id: $backupId) {
        url
        expires_at
      }
    }`,

  addPostgresFollower: `mutation AddPostgresFollower($addonUUID: ID!, $plan: String!, $region: String!) {
      addPostgresFollower(
        leader_uuid: $addonUUID
        plan: $plan
        region: $region
      ) {
        id
        name
      }
    }`,

  unfollowPostgres: `mutation UnfollowPostgres($addonUUID: ID!) {
      unfollowPostgres(
        addon_uuid: $addonUUID
      )
    }`,

  removePostgres: `mutation RemovePostgres($addonUUID: ID!) {
      removePostgres(
        addon_uuid: $addonUUID
      )
    }`,

  resetPostgres: `mutation ResetPostgres($addonUUID: ID!) {
      resetPostgres(
        addon_uuid: $addonUUID
      )
    }`,

  rollbackPostgres: `mutation RollbackPostgres($addonUUID: ID!, $plan: String!, $to: String!, $region: String!) {
    rollbackPostgres(
      addon_uuid: $addonUUID
      plan: $plan
      to: $to
      region: $region
    ) {
      id
      name
      plan
    }
  }`,

  forkPostgres: `mutation CreatePostgresFork($addonUUID: ID!, $plan: String!, $region: String!) {
    createPostgresFork(
      addon_uuid: $addonUUID
      plan: $plan
      region: $region
    )
  }`,

  fetchDatastoreMetrics: `query MetaasQuery($addonUUID: ID!, $service: String!, $start: String!, $end: String!, $resolution: String!, $filter: String) {
    metaas(
      addon_uuid: $addonUUID
      service: $service
      start: $start
      end: $end
      step: $resolution
      filter: $filter
    ) {
      name
      measurements
    }
  }`,

  createDataclip: `mutation CreateDataclip($attachmentId: ID!, $title: String!, $sql: String!, $teamId: ID, $status: ClipStatusType) {
    createClip(attachmentId: $attachmentId, title: $title, sql: $sql, teamId: $teamId, status: $status) {
      ...clipFragment
    }
  }${clipFragment}`,

  deleteDataclip: `mutation DeleteDataclip($clipId: ID!) {
    deleteClip(clipId: $clipId)
  }`,

  updateDataclip: `mutation UpdateDataclip($clipId: ID!, $attachmentId: ID, $title: String, $sql: String, $status: ClipStatusType) {
    updateClip(clipId: $clipId, attachmentId: $attachmentId, title: $title, sql: $sql, status: $status) {
      ...clipFragment
    }
  }${clipFragment}`,

  fetchClipDetails: `query FetchClipDetails($slug: ID!) {
    clip(slug: $slug) {
      ...clipFragment
    }
  }${clipFragment}`,

  shareDataclipWithUser: `mutation ShareDataclipWithUser($clipId: ID!, $email: String!) {
    shareClipWithUser(clipId: $clipId, email: $email) {
      id
      created_at
      clip_id
      shared_by {
        id
        email
      }
      shared_with {
        id
        email
      }
    }
  }`,

  shareDataclipWithTeam: `mutation ShareDataclipWithTeam($clipId: ID!, $teamId: ID!) {
    shareClipWithTeam(clipId: $clipId, teamId: $teamId) {
      id
      created_at
      clip_id
      shared_by {
        id
        email
      }
      shared_with {
        id
        name
      }
    }
  }`,

  unshareDataclipWithUser: `mutation UnshareDataclipWithUser($clipId: ID!, $clipShareId: ID!) {
    unshareClipWithUser(clipId: $clipId, clipShareId: $clipShareId)
  }`,

  unshareDataclipWithTeam: `mutation UnshareDataclipWithTeam($clipId: ID!, $clipShareId: ID!) {
    unshareClipWithTeam(clipId: $clipId, clipShareId: $clipShareId)
  }`,

  sharePublicDataclip: `mutation SharePublicDataclip($slug: ID!) {
    togglePublicClipShare(slug: $slug, enabled: true) {
      ...clipFragment
    }
  }${clipFragment}`,

  unsharePublicDataclip: `mutation UnsharePublicDataclip($slug: ID!) {
    togglePublicClipShare(slug: $slug, enabled: false) {
      ...clipFragment
    }
  }${clipFragment}`,

  refreshClipAccessToken: `mutation RefreshClipAccessToken($slug: ID!) {
    refreshClipAccessToken(slug: $slug)
  }`,

  listClips: `query ListClips {
    listClips {
      id
      slug
      status
      title
      creator {
        id
        email
      }
      created_at
      edited_at
      datasource {
        addon_name
        attachment_id
      }
      detached
      editable
    }
  }`,

  listApps: `query ListApps {
    listApps {
      id
      name
      owner_id
    }
  }`,

  listTeams: `query ListTeams {
    listTeams {
      id
      name
      role
      type
      default
      enterprise_account {
        id
        name
      }
    }
  }`,

  createCredential: `mutation CreateCredential($addonUUID: ID!, $name: String!) {
    createCredential(
      addon_uuid: $addonUUID
      name: $name
    )
  }`,

  rotateCredential: `mutation RotateCredential($addonUUID: ID!, $name: String!, $forced: Boolean!) {
    rotateCredential(
      addon_uuid: $addonUUID
      name: $name
      forced: $forced
    )
  }`,

  destroyCredential: `mutation DestroyCredential($addonUUID: ID!, $name: String!) {
    destroyCredential(
      addon_uuid: $addonUUID
      name: $name
    )
  }`,

  rotateAllCredentials: `mutation RotateCredential($addonUUID: ID!, $forced: Boolean!) {
    rotateAllCredentials(
      addon_uuid: $addonUUID
      forced: $forced
    )
  }`,

  attachCredential: `mutation AttachCredential($addonUUID: ID!, $credential: String!, $appID: ID!, $attachment: String!) {
    attachCredential(
      addon_uuid: $addonUUID
      credential: $credential
      app_id: $appID
      attachment: $attachment
    ) {
      id, name, app_name, app_uuid, namespace
    }
  }`,

  detachCredential: `mutation DetachCredential($attachmentID: ID!) {
    detachCredential(attachment_id: $attachmentID) {
      id
    }
  }`,

  configurePostgres: `mutation ConfigurePostgres($addonUUID: ID!, $settings: JSON!) {
    configurePostgres(
      addon_uuid: $addonUUID
      settings: $settings
    ) {
      name, value, default
    }
  }`,

  refreshPostgresSchema: `mutation RefreshPostgresSchema($addonUUID: ID!) {
    refreshSchema(addonUUID: $addonUUID) {
      addonUUID
    }
  }`,

  fetchPostgresSchema: `query FetchSchema($addonUUID: ID!) {
    postgresSchema(addon_uuid: $addonUUID) {
      name
      owner
      search_path
      last_modified
      acls {...ACLFragment}
      default_acls {...DefaultACLFragment}
      roles {
        name
        memberships
        superuser
      }
      schemas {
        oid
        name
        owner
        acls {...ACLFragment}
        default_acls {...DefaultACLFragment}
        relations {
          oid
          name
          owner
          acls {...ACLFragment}
          kind
          columns {
            name
            number
            type_oid
            nullable
            default
          }
          indexes {
            oid
            name
            primary_key
            columns
          }
          dependencies
        }
        sequences {
          oid
          name
          owner
          acls {...ACLFragment}
        }
        types {
          oid
          name
          owner
        }
      }
    }
  }
  fragment ACLFragment on PostgresSchemaACL {
    role
    privileges
  }
  fragment DefaultACLFragment on PostgresSchemaDefaultACL {
    creator
    object_type
    role
    privileges
  }`,

  diagnosePostgres: `query PostgresDiagnose($addonUUID: ID!) {
  postgresDiagnose(addon_uuid: $addonUUID) {
    name
    status
    details
  }
}
`,

  terminatePostgresConnection: `mutation TerminatePostgresConnection($addonUUID: ID!, $pid: ID!) {
    terminatePostgresConnection(
      addon_uuid: $addonUUID
      pid: $pid
    )
  }`,

  setPostgresPermissions: `mutation SetPostgresPermissions($addonUUID: ID!, $role: String!, $acls: [PostgresACLInput]!) {
    setPostgresPermissions(
      addon_uuid: $addonUUID
      role: $role
      acls: $acls
    )
  }`,

  fetchPostgresPrivatelink: `query FetchPostgresPrivatelink($addonUUID: ID!) {
    postgresPrivatelink(addonUUID: $addonUUID) {
      ...privatelinkFragment
    }
  }${privatelinkFragment}`,

  createPostgresPrivatelink: `mutation CreatePostgresPrivatelink($addonUUID: ID!, $whitelistedAccounts: [String]!) {
    createPostgresPrivatelink (addonUUID: $addonUUID, whitelistedAccounts: $whitelistedAccounts) {
      ...privatelinkFragment
    }
  }${privatelinkFragment}`,

  destroyPostgresPrivatelink: `mutation DestroyPostgresPrivatelink($addonUUID: ID!) {
    destroyPostgresPrivatelink (addonUUID: $addonUUID)
  }`,

  addPostgresPrivatelinkWhitelist: `mutation AddPostgresPrivatelinkWhitelist($addonUUID: ID!, $whitelistedAccounts: [String]!) {
    addPostgresPrivatelinkWhitelist (addonUUID: $addonUUID, whitelistedAccounts: $whitelistedAccounts) {
      ...privatelinkFragment
    }
  }${privatelinkFragment}`,

  removePostgresPrivatelinkWhitelist: `mutation RemovePostgresPrivatelinkWhitelist($addonUUID: ID!, $whitelistedAccounts: [String]!) {
    removePostgresPrivatelinkWhitelist (addonUUID: $addonUUID, whitelistedAccounts: $whitelistedAccounts) {
      ...privatelinkFragment
    }
  }${privatelinkFragment}`,

  listIntegrations: `query ListIntegrations {
    connectEventsInstanceList {
      ...instanceFragment
    }
  }${instanceFragment}`,

  fetchIntegration: `query FetchIntegration($uuid: ID!) {
    connectEventsInstance (uuid: $uuid) {
      ...instanceFragment
    }
  }${instanceFragment}`,

  configureIntegration: `mutation ConnectConfigureInstance(
    $uuid: ID!,
    $prefix: String!,
    $dbKey: String!,
    $environment: String!,
    $version: String!,
    $next: String
  ) {
    connectConfigureInstance(
      uuid: $uuid
      prefix: $prefix
      dbKey: $dbKey
    ) {
      id
      created_at
      state
      auth_updated
      app_name
      app_id
      plan
      resource_name
      db_key
      schema_name
      authorization_url(environment: $environment, version: $version, next: $next)
    }
  }`,

  activateEvent: `mutation ConnectActivateEvent(
    $uuid: ID!,
    $name: String!
  ) {
    connectActivateEvent(
      uuid: $uuid
      name: $name
    ) {
      id
      created_at
      detail_url
      object_name
      topic_name
      state
    }
  }`,

  deactivateEvent: `mutation ConnectDeactivateEvent(
    $streamId: ID!,
  ) {
    connectDeactivateEvent(streamId: $streamId)
  }`,

  pauseConnectEvent: `mutation ConnectEventsInstancePauseMutation(
    $addonId: ID!,
  ) {
    connectEventsInstancePauseMutation(addonId: $addonId)
  }`,

  resumeConnectEvent: `mutation ConnectEventsInstanceResumeMutation(
    $addonId: ID!,
  ) {
    connectEventsInstanceResumeMutation(addonId: $addonId)
  }`,
  deleteConnection: `mutation DeleteConnectionMutation(
    $addon: ID!,
    $app: String!
  ) {
    deleteConnectionMutation(addon: $addon, app: $app) {
      id
    }
  }
  `
}
