import { call, put } from 'client/lib/operation/effects'
import GraphQLAPI from 'client/lib/graphqlapi'
import { dataclipActions } from 'client/reducers/dataclips'

const api = new GraphQLAPI()

export default function * refreshClipAccessToken ({slug}) {
  const access_token = yield call([api, api.refreshClipAccessToken], slug)
  const updateAccessTokenAction = {
    type: dataclipActions.DATACLIPS_MERGE,
    payload: {
      slug,
      access_token
    }
  }
  yield put(updateAccessTokenAction)
}
