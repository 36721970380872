import { DashboardStorage } from '../utils'

function AuthWorkflow() {
  const cachedReturnTo = DashboardStorage.getSessionItem('hk-return-to')
  if (!cachedReturnTo) {
    const returnTo = `${window.location.pathname}${window.location.search}${
      window.location.hash
    }`
    DashboardStorage.setSessionItem('hk-return-to', returnTo)
  }

  const callbackUrl = `${window.location.origin}/auth/heroku/callback`
  window.location.assign(
    `${
      process.env.REACT_APP_PARTICLEBOARD_URL
    }/login?state=${encodeURIComponent(callbackUrl)}`
  )
  return null
}

export default AuthWorkflow
