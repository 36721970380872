import React from 'react'
import PropTypes from 'prop-types'

import { HKButton, HKDropdown } from '@heroku/react-hk-components'
import { MalibuIcon } from '@heroku/react-malibu'

import { downloadResults, buildResultsUrl } from 'client/helpers/dataclipDownload'
import analytics from 'client/helpers/analytics'
import ReadOnlyInput from 'client/components/ReadOnlyInput'
import DataclipRefreshAccessToken from 'client/components/DataclipRefreshAccessToken'

export default class DataclipSharingDropdown extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string,
    clipSlug: PropTypes.string.isRequired,
    editable: PropTypes.bool,
  }

  handleCsvDownload = () => {
    const {clipSlug} = this.props
    downloadResults(clipSlug, 'csv')
  }

  handleJsonDownload = () => {
    const {clipSlug} = this.props
    downloadResults(clipSlug, 'json')
  }

  handleGoogleSheetsLinkCopied = () => {
    analytics.track('Dataclip Google Sheets Link Copied')
  }

  render () {
    const { accessToken, clipSlug, editable } = this.props
    const csvUrl = buildResultsUrl(clipSlug, 'csv', accessToken)
    const jsonUrl = buildResultsUrl(clipSlug, 'json', accessToken)
    const importDataUrl = buildResultsUrl(clipSlug, 'csv', accessToken)
    const importData = `=IMPORTDATA("${importDataUrl}")`

    return (
      <HKDropdown name='dataclip-sharing' align='right' className='mr2' contentClassName='w-25-rem' closeOnClick={false} title='Download/Export'>
        <li className='pa2 flex-column'>
          <h1 className='dark-gray normal f5 mt0'>Download Results</h1>
          <div className='flex nowrap'>
            <HKButton type='secondary' className='br--left nudge-right--1 flex items-center' onClick={this.handleCsvDownload}>
              <MalibuIcon name='direction-down-16' size={16} fillClass='fill-purple' />
              CSV
            </HKButton>
            <HKButton type='secondary' className='br--right flex items-center' onClick={this.handleJsonDownload}>
              <MalibuIcon name='direction-down-16' size={16} fillClass='fill-purple' />
              JSON
            </HKButton>
          </div>
        </li>
        <li className='pa2 flex-column bt b--silver'>
          <h1 className='dark-gray normal f5 mt0'>CSV URL</h1>
          <ReadOnlyInput value={csvUrl} />
        </li>
        <li className='pa2 flex-column'>
          <h1 className='dark-gray normal f5 mt0'>JSON URL</h1>
          <ReadOnlyInput value={jsonUrl} />
        </li>
        <li className='pa2 flex flex-column ws-normal lh-copy bt b--silver'>
          <h1 className='dark-gray normal f5 mt0'>Google Sheets Integration</h1>
          <ReadOnlyInput onSelect={this.handleGoogleSheetsLinkCopied} value={importData} />
          <p className='gray'>
            Use this macro to import the Dataclip result data into <a href='http://sheets.google.com/' target='_blank' rel='noopener noreferrer' className='gray'>Google Sheets</a>.
          </p>

          <p className='purple mv0'>
            Visit <a target='_blank' rel='noopener noreferrer' className='purple' href='https://support.google.com/docs/answer/3093335?hl=en'>Google&apos;s documentation</a> for more info about this feature.
          </p>
        </li>
        {editable && (
          <li className='pa2 bt b--silver'>
            <p className='gray ws-normal lh-copy'>
              <strong>Keep these URLs secret.</strong> Anyone who has these URLs can access the results of this dataclip.
            </p>
            <DataclipRefreshAccessToken clipSlug={clipSlug} />
          </li>
        )}
      </HKDropdown>
    )
  }
}
