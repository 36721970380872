import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { HKButton } from '@heroku/react-hk-components'

import asyncState from 'client/constants/asyncState'
import withOperation from 'client/lib/operation/withOperation'
import refreshClipAccessToken from 'client/sagas/refreshClipAccessToken'

export class DataclipRefreshAccessToken extends Component {
  static propTypes = {
    clipSlug: PropTypes.string.isRequired,
    operation: PropTypes.object
  }

  handleRefreshAccessToken = () => {
    const { operation, clipSlug } = this.props
    operation.trigger({slug: clipSlug})
  }

  render () {
    const { operation } = this.props
    const pending = operation.status === asyncState.PENDING
    let infoMessage = 'Use with caution, as this will invalidate any pre-existing CSV/JSON download URLs.'

    switch (operation.status) {
      case asyncState.FULFILLED:
        infoMessage = 'Your old token has been invalidated.'
        break
      case asyncState.REJECTED:
        infoMessage = 'Error while invalidating your old token.'
        break
    }

    return (
      <div className='ws-normal'>
        <HKButton small type='danger' async={pending} onClick={this.handleRefreshAccessToken}>
          Refresh Access Token
        </HKButton>
        <p className='lh-copy mt2 mb0 gray'>{infoMessage}</p>
      </div>
    )
  }
}

export default withOperation(DataclipRefreshAccessToken, {
  generator: refreshClipAccessToken,
  expireAfter: 3000
})
