import analytics from 'client/helpers/analytics'
import { getLocation } from 'client/helpers/getLocation'

export function downloadResults (slug, format) {
  const location = getLocation()

  analytics.track(`Dataclip Results ${format.toUpperCase()} Downloaded`)
  location.href = buildResultsUrl(slug, format)
}

export function buildResultsUrl (slug, format, accessToken = null) {
  const location = getLocation()
  let url = `${location.protocol}//${location.host}/dataclips/${slug}.${format}`

  if (accessToken) {
    url += `?access-token=${accessToken}`
  }

  return url
}
