import axios from 'axios'
import * as gql from './graphql_queries'
import parseInterval from 'client/lib/parseInterval'
import logger from 'client/helpers/logger'
import { DashboardStorage } from '../../utils';

function GraphQLError (message = 'Errors in GraphQL response', query, errors) {
  this.name = 'GraphQLError'
  this.message = message
  this.stack = (new Error()).stack
  this.query = query
  this.errors = errors
}

export default class GraphQLAPI {
  handleError (err, query, variables) {
    if (err.response) {
      // request was made, but server responded with non-2xx
      if (err.response.status !== 401) {
        // every time a user leaves a tab open for a datastore they are not
        // permitted to see, they generate a rollbar for each attempt, resulting
        // in many rollbars per hour. Don't report these to rollbar for now.
        // TODO: figure out how to better monitor this sort of thing.
        window.Rollbar.error(err.response, {query, variables})
      }
    } else {
      // something went wrong in before axios performed the request
      // or we got an error GraphQL response (in a successful http response)
      window.Rollbar.error(err)
    }
  }

  parseGraphQLResponse (response, query) {
    const {data, errors} = response.data
    if (errors) {
      logger.error('GraphQL response included errors:', query, errors)
      const isAuthError = (e) => {
        return e.message === 'Request failed with status code 401' ||
          /^Error during.*: 401$/.test(e.message)
      }
      const authError = errors.some(isAuthError)
      if (authError) {
        if (window.paused) {
          logger.debug('paused: ignoring GraphQL 401')
        } else {
          logger.debug('GraphQL 401 triggered!')
          const impersonatedUser = DashboardStorage.getSessionItem('hk-sudo-data')
        
          DashboardStorage.clear()
          
          if (!impersonatedUser) {
            const returnTo = `${window.location.pathname}${window.location.search}${
              window.location.hash
            }`
            DashboardStorage.setSessionItem('hk-return-to', returnTo)
          } else {
            DashboardStorage.clearSession()
          }

          window.location.assign('/')
        }
      }
      return Promise.reject(new GraphQLError('GraphQL query partially successful, with errors', query, errors))
    }
    return data
  }

  _get (query, variables, background = false) {
    return axios.get('/graphql',
      {params: {query, variables},
      headers: {
        'X-Background-Request': background,
        'X-Heroku-Data-Tab': sessionStorage.getItem('herokudataTabId')
    }})
      .then((response) => this.parseGraphQLResponse(response, query))
      .catch((err) => {
        // this only handles errors arising from axios requests
        // see the body of parseGraphQLResponse for handling of graphql errors
        // (e.g. inside graphql responses).
        this.handleError(err, query, variables)
        throw err
      })
  }

  _post (query, variables, background = false) {
    return axios.post('/graphql',
      {query, variables},
      { headers: {
        'X-Background-Request': background,
        'X-Heroku-Data-Tab': sessionStorage.getItem('herokudataTabId')
      }
    })
      .then((response) => this.parseGraphQLResponse(response, query))
      .catch((err) => {
        // this only handles errors arising from axios requests
        // see the body of parseGraphQLResponse for handling of graphql errors
        // (e.g. inside graphql responses).
        this.handleError(err, query, variables)
        throw err
      })
  }

  userQuery (background = false) {
    return this._get(gql.fetchUser, {}, background)
      .then((data) => data.user)
  }

  fetchDatastoreDetails (addonUUID, service, background = false) {
    const serviceName = window.service
    switch (service) {
      case serviceName.kafka:
        return this.fetchKafkaDetails(addonUUID, background)
      case serviceName.cassandra:
        return this.fetchCassandraDetails(addonUUID, background)
      case serviceName.redis:
        return this.fetchRedisDetails(addonUUID, background)
      case serviceName.postgres:
        return this.fetchPostgresqlDetails(addonUUID, background)
      default:
        throw new Error(`Unknown service: ${service}`)
    }
  }

  fetchKafkaDetails (addonUUID, background = false) {
    return this._get(gql.fetchKafkaDetails, { addonUUID }, background)
      .then((data) => data.kafka)
  }

  fetchCassandraDetails (addonUUID, background = false) {
    return this._get(gql.fetchCassandraDetails, { addonUUID }, background)
      .then((data) => data.cassandra)
  }

  fetchRedisDetails (addonUUID, background = false) {
    return this._get(gql.fetchRedisDetails, { addonUUID }, background)
      .then((data) => data.redis)
  }

  fetchPostgresqlDetails (addonUUID, background = false) {
    return this._get(gql.fetchPostgresqlDetails, { addonUUID }, background)
      .then((data) => data.postgres)
  }

  createKafkaTopic (addonUUID, topic) {
    const args = {
      addonUUID,
      topic: topic.name,
      rf: topic.replicationFactor,
      partitions: topic.partitions,
      compaction: topic.compactionEnabled,
      retentionTime: topic.retentionTime,
    }
    return this._post(gql.createKafkaTopic, args)
  }

  updateKafkaTopic (addonUUID, topic) {
    const args = {
      addonUUID,
      topic: topic.name,
      rf: topic.replicationFactor,
      compaction: topic.compactionEnabled,
      retentionTime: topic.retentionTime,
    }
    return this._post(gql.updateKafkaTopic, args)
  }

  deleteKafkaTopic (addonUUID, topicName) {
    return this._post(gql.deleteKafkaTopic, { addonUUID, topic: topicName })
  }

  createPostgresBackup (addonUUID) {
    return this._post(gql.createPostgresBackup, { addonUUID })
  }

  deletePostgresBackup (addonUUID, backupId) {
    return this._post(gql.deletePostgresBackup, { addonUUID, backupId })
  }

  generatePostgresBackupUrl (addonUUID, backupId) {
    return this._post(gql.generatePostgresBackupUrl, { addonUUID, backupId })
  }

  addPostgresFollower (addonUUID, plan, region) {
    return this._post(gql.addPostgresFollower, { addonUUID, plan, region })
  }

  unfollowPostgres (addonUUID) {
    return this._post(gql.unfollowPostgres, { addonUUID })
  }

  removePostgres (addonUUID) {
    return this._post(gql.removePostgres, { addonUUID })
  }

  resetPostgres (addonUUID) {
    return this._post(gql.resetPostgres, { addonUUID })
  }

  rollbackPostgres (addonUUID, plan, to, region) {
    return this._post(gql.rollbackPostgres, { addonUUID, plan, to, region })
      .then((data) => data.rollbackPostgres)
  }

  forkPostgres (addonUUID, plan, region) {
    return this._post(gql.forkPostgres, { addonUUID, plan, region })
  }

  configurePostgres (addonUUID, settings) {
    return this._post(gql.configurePostgres, { addonUUID, settings })
      .then((data) => data.configurePostgres)
  }

  fetchPostgresPrivatelink (addonUUID) {
    return this._get(gql.fetchPostgresPrivatelink, { addonUUID })
      .then((data) => data.postgresPrivatelink)
  }

  createPostgresPrivatelink (addonUUID, whitelistedAccounts) {
    return this._post(gql.createPostgresPrivatelink, { addonUUID, whitelistedAccounts })
      .then((data) => data.createPostgresPrivatelink)
  }

  destroyPostgresPrivatelink (addonUUID) {
    return this._post(gql.destroyPostgresPrivatelink, { addonUUID })
      .then((data) => data.destroyPostgresPrivatelink)
  }

  addPostgresPrivatelinkWhitelist (addonUUID, whitelistedAccounts) {
    return this._post(gql.addPostgresPrivatelinkWhitelist, { addonUUID, whitelistedAccounts })
      .then((data) => data.addPostgresPrivatelinkWhitelist)
  }

  removePostgresPrivatelinkWhitelist (addonUUID, whitelistedAccounts) {
    return this._post(gql.removePostgresPrivatelinkWhitelist, { addonUUID, whitelistedAccounts })
      .then((data) => data.removePostgresPrivatelinkWhitelist)
  }

  fetchDatastoreMetrics (config, background = false) {
    let {
      addonUUID,
      service,
      start,
      end,
      resolution,
      filter
    } = config

    return this._get(gql.fetchDatastoreMetrics, { addonUUID, service, start, end, resolution, filter }, background)
      .then((data) => {
        // Our GQL interface to metaas returns data like:
        // [
        //  {
        //    name: "foo",
        //    measurements: [123, 456, 789, ...]
        //   }
        //   ...
        // ]
        //
        // We need to transform the measurements into an array like
        // [
        //  {
        //    name: "foo",
        //    measurements: [
        //      { time: "2017-01-24T19:00:00.000Z", value: 123 },
        //      { time: "2017-01-24T19:10:00.000Z", value: 456 },
        //      ...
        //    ]
        //  },
        //  ...
        // ]
        //
        // N.B.: Metaas operates on window *opening* times, but window
        // *closing* times are more natural here, hence the i + 1 below
        const rangeStart = Date.parse(start)
        const step = parseInterval(resolution)

        const metrics = data.metaas.map((metric) => {
          return {
            name: metric.name,
            measurements: metric.measurements.map((value, i) => (
              {
                time: new Date(rangeStart + ((i + 1) * step)).toISOString(),
                value
              }
            ))
          }
        })
        return metrics
      })
  }

  createDataclip (attachmentId, title, sql, teamId, status) {
    return this._post(gql.createDataclip, { attachmentId, title, sql, teamId, status })
      .then((data) => data.createClip)
  }

  deleteDataclip (clipId) {
    return this._post(gql.deleteDataclip, {clipId})
      .then((data) => data.deleteClip)
  }

  updateDataclip (clipId, attachmentId, title, sql, status) {
    return this._post(gql.updateDataclip, {clipId, attachmentId, title, sql, status})
      .then((data) => data.updateClip)
  }

  fetchDataclipDetails (slug, background) {
    return this._get(gql.fetchClipDetails, {slug}, background)
      .then((data) => data.clip)
  }

  listClips (background = false) {
    return this._get(gql.listClips, {}, background)
      .then((data) => data.listClips)
  }

  shareDataclipWithUser (clipId, email) {
    return this._post(gql.shareDataclipWithUser, {clipId, email})
      .then((data) => data.shareClipWithUser)
  }

  shareDataclipWithTeam (clipId, teamId) {
    return this._post(gql.shareDataclipWithTeam, {clipId, teamId})
      .then((data) => data.shareClipWithTeam)
  }

  unshareDataclipWithUser (clipId, clipShareId) {
    return this._post(gql.unshareDataclipWithUser, {clipId, clipShareId})
      .then((data) => data.unshareClipWithUser)
  }

  unshareDataclipWithTeam (clipId, clipShareId) {
    return this._post(gql.unshareDataclipWithTeam, {clipId, clipShareId})
      .then((data) => data.unshareClipWithTeam)
  }

  sharePublicDataclip (slug) {
    return this._post(gql.sharePublicDataclip, {slug})
      .then((data) => data.togglePublicClipShare)
  }

  unsharePublicDataclip (slug) {
    return this._post(gql.unsharePublicDataclip, {slug})
      .then((data) => data.togglePublicClipShare)
  }

  refreshClipAccessToken (slug) {
    return this._post(gql.refreshClipAccessToken, {slug})
      .then((data) => data.refreshClipAccessToken)
  }

  createCredential (addonUUID, name) {
    return this._post(gql.createCredential, { addonUUID, name })
      .then((data) => data.createCredential)
  }

  rotateCredential (addonUUID, name, forced) {
    return this._post(gql.rotateCredential, { addonUUID, name, forced })
      .then((data) => data.rotateCredential)
  }

  destroyCredential (addonUUID, name) {
    return this._post(gql.destroyCredential, { addonUUID, name })
      .then((data) => data.destroyCredential)
  }

  rotateAllCredentials (addonUUID, forced) {
    return this._post(gql.rotateAllCredentials, { addonUUID, forced })
      .then((data) => data.rotateAllCredentials)
  }

  attachCredential (addonUUID, credential, appID, attachment) {
    return this._post(gql.attachCredential, {
      addonUUID,
      credential,
      appID,
      attachment,
    })
      .then((data) => data.attachCredential)
  }

  detachCredential (attachmentID) {
    return this._post(gql.detachCredential, { attachmentID })
      .then((data) => data.detachCredential)
  }

  listApps (background = false) {
    return this._get(gql.listApps, {}, background)
      .then((data) => data.listApps)
  }

  listTeams (background = false) {
    return this._get(gql.listTeams, {}, background)
      .then((data) => data.listTeams)
  }

  fetchPostgresSchema (addonUUID, background = false) {
    return this._get(gql.fetchPostgresSchema, { addonUUID }, background)
      .then((data) => data.postgresSchema)
  }

  refreshPostgresSchema (addonUUID) {
    return this._post(gql.refreshPostgresSchema, { addonUUID })
      .then((data) => data.refreshSchema)
  }

  diagnosePostgres (addonUUID, background = false) {
    return this._get(gql.diagnosePostgres, { addonUUID }, background)
      .then((data) => data.postgresDiagnose)
  }

  terminatePostgresConnection (addonUUID, pid) {
    return this._post(gql.terminatePostgresConnection, { addonUUID, pid })
      .then((data) => data.postgresTerminate)
  }

  setPostgresPermissions (addonUUID, role, acls) {
    return this._post(gql.setPostgresPermissions, {
      addonUUID,
      role,
      acls,
    }).then((data) => data.setPostgresPermissions)
  }

  listIntegrations (background = false) {
    return this._get(gql.listIntegrations, {}, background)
      .then((data) => data.connectEventsInstanceList)
  }

  fetchIntegration (uuid, background = false) {
    return this._get(gql.fetchIntegration, {uuid}, background)
      .then((data) => data.connectEventsInstance)
  }

  configureIntegration (uuid, dbKey, prefix, environment, version, next) {
    return this._post(gql.configureIntegration, {
      uuid,
      dbKey,
      prefix,
      environment,
      version,
      next,
    })
      .then((data) => data.connectConfigureInstance)
  }

  activateEvent (addonId, objectId) {
    return this._post(gql.activateEvent, {
      uuid: addonId,
      name: objectId,
    })
      .then((data) => data.connectActivateEvent)
  }

  deleteConnection ({addon, app}) {
    return this._post(gql.deleteConnection, {addon, app})
      .then((data) => data.deleteConnection)
  }

  deactivateEvent (streamId) {
    return this._post(gql.deactivateEvent, {streamId})
      .then((data) => data.connectDeactivateEvent)
  }

  pauseConnectEvent (addonId) {
    return this._post(gql.pauseConnectEvent, {addonId})
    .then(data => data)
  }

  resumeConnectEvent (addonId) {
    return this._post(gql.resumeConnectEvent, {addonId})
    .then(data => data)
  }
}
