import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import DataclipResult from 'client/components/DataclipResult'
import DataclipResultError from 'client/components/DataclipResultError'
import DataclipResultSpinner from 'client/components/DataclipResultSpinner'

export default class DataclipResultSection extends React.Component {
  static propTypes = {
    dataclip: PropTypes.instanceOf(Immutable.Map).isRequired,
    fetchingResults: PropTypes.bool,
    hasNewResults: PropTypes.bool,
    latestResultAt: PropTypes.string,
    onRefreshResults: PropTypes.func,
    resultMetadata: PropTypes.instanceOf(Immutable.Map).isRequired,
    results: PropTypes.instanceOf(Immutable.Map),
    resultsError: PropTypes.string
  }

  render () {
    const { dataclip, hasNewResults, onRefreshResults, resultMetadata, latestResultAt, fetchingResults, results, resultsError } = this.props
    const clipSlug = dataclip.get('slug')
    const accessToken = dataclip.get('access_token')
    const showErrors = !!resultsError
    const showResult = !showErrors && !fetchingResults

    return (
      <React.Fragment>
        {fetchingResults && <DataclipResultSpinner message='Fetching results…' />}
        {showErrors && <DataclipResultError errorMessage={resultsError} />}
        {showResult && <DataclipResult
          accessToken={accessToken}
          hasNewResults={hasNewResults}
          onRefreshResults={onRefreshResults}
          editable={dataclip.get('editable')}
          clipSlug={clipSlug}
          latestResultAt={latestResultAt}
          resultData={results}
          resultMetadata={resultMetadata} />}
      </React.Fragment>
    )
  }
}
