import axios from 'axios'
const getBearerToken = async ({ code }) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_PARTICLEBOARD_URL}/login/token`,
      {
        code,
        grant_type: 'code'
      }
    )
    return res.data
  } catch (e) {
    throw new Error('Failed to get token from Particleboard')
  }
}

export default getBearerToken
