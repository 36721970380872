import React from 'react'
import ReactDOM from 'react-dom'

import './index.css'
import App from './App.js'
import * as serviceWorker from './serviceWorker'
import { HKIconSprites } from '@heroku/react-hk-components'

import { UserProvider } from './context/user'
import { initRollbar } from './utils'

initRollbar()

ReactDOM.render(
  <React.Fragment>
    <HKIconSprites />
    <HKIconSprites set="marketing" />
    <UserProvider>
      <App />
    </UserProvider>
  </React.Fragment>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
