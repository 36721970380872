import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

export default class Spinner extends React.Component {
  static propTypes = {
    button: PropTypes.bool,
    className: PropTypes.string,
    inverted: PropTypes.bool
  }

  static defaultProps = {
    button: false,
    className: 'ma2',
    inverted: false,
  }

  render () {
    const { button, className, inverted } = this.props
    const outerClasses = classnames(className, {
      'hk-spinner': !button,
      'hk-button__spinner': button,
    })
    const dotClasses = classnames({
      'hk-spinner__dot': !button,
      'hk-button__spinner__dot': button,
      'hk-button__spinner__dot--inverted': !button && inverted,
    })
    if (button) {
      return (
        <span className={outerClasses}>
          <i className={classnames(dotClasses, 'hk-button__spinner__dot--one')}></i>
          <i className={classnames(dotClasses, 'mh1', 'hk-button__spinner__dot--two')}></i>
          <i className={classnames(dotClasses, 'hk-button__spinner__dot--three')}></i>
        </span>
      )
    } else {
      return (
        <div className={outerClasses}>
          <i className={classnames(dotClasses, 'hk-spinner__dot--one')}></i>
          <i className={classnames(dotClasses, 'mh1', 'hk-spinner__dot--two')}></i>
          <i className={classnames(dotClasses, 'hk-spinner__dot--three')}></i>
        </div>
      )
    }
  }
}
