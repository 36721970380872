import axios from 'axios'
import logger from 'client/helpers/logger'

export function track (event, properties = {}) {
  const {id, allowTracking} = (window.impersonatedUser || window.user || {id: 'anonymous', allowTracking: true})
  const { sendAnalytics } = window
  const userId = id

  if (!sendAnalytics || !allowTracking) {
    console.debug('(not sending) analytics.track(): ', event, properties)
    return
  } else {
    console.debug('analytics.track(): ', event, properties)
  }

  try {
    const payload = btoa(JSON.stringify({
      source: 'dod',
      userId,
      event,
      properties,
      page: {
        url: window.location.href,
        path: window.location.pathname
      }
    }))

    axios.get(`https://backboard.heroku.com/hamurai?data=${payload}`, {
      transformRequest (data, headers) {
        // We get CORS errors if we don't delete these headers.
        // Keep in mind these are only being deleted
        // on the individual request, not globally
        delete headers.common['Client-Server-Api-Contract-Version']
        delete headers.common['CSRF-Token']
        delete headers['X-Request-ID']
        delete headers.common['X-Refresh-Token']
        delete headers.common['Authorization']
      }
    })
  } catch (e) {
    logger.error(`Analytics error: ${e}`)
  }
}

export function page (page, properties = {}) {
  track('Page visited', {page, ...properties})
}

const analytics = {
  page,
  track,
}

export default analytics
