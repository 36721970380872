import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import moment from 'moment'
import { HKButton } from '@heroku/react-hk-components'
import { numberPrettifier, durationPrettifier } from 'client/lib/prettifiers'
import classnames from 'classnames'
import ContainerDimensions from 'react-container-dimensions'

import DataclipResultTable from 'client/components/DataclipResultTable'
import DataclipSharingDropdown from 'client/components/DataclipSharingDropdown'
import DataclipVisualization from 'client/components/DataclipVisualization'
import Spinner from 'client/components/Spinner'
import Tooltip from 'client/components/Tooltip'

import getVisualizationProps from 'client/helpers/getVisualizationProps'
import analytics from 'client/helpers/analytics'

export default class DataclipResult extends React.Component {
  static propTypes = {
    accessToken: PropTypes.string,
    clipSlug: PropTypes.string.isRequired,
    editable: PropTypes.bool,
    hasNewResults: PropTypes.bool,
    latestResultAt: PropTypes.string,
    onRefreshResults: PropTypes.func,
    resultData: PropTypes.instanceOf(Immutable.Map),
    resultMetadata: PropTypes.instanceOf(Immutable.Map),
  }

  state = {
    resultsTab: 'data',
  }

  componentDidMount () {
    const { resultMetadata } = this.props
    if (resultMetadata) {
      analytics.track('Dataclip Results Loaded', {
        duration: resultMetadata.get('duration')
      })
    }
  }

  handleTabNavigation = (e) => {
    e.preventDefault()
    const resultsTab = e.currentTarget.getAttribute('title')
    this.setState({ resultsTab })
  }

  render () {
    const { accessToken, hasNewResults, onRefreshResults, latestResultAt, clipSlug, resultData, resultMetadata, editable } = this.props
    const { resultsTab } = this.state
    let resultTable
    let resultVisualization
    let showChart = false

    if (resultMetadata && resultData && resultData.get('data')) {
      resultTable = (<DataclipResultTable data={resultData.get('data')} />)

      const visualizationProps = getVisualizationProps(resultData.get('data').toJS())
      if (visualizationProps) {
        resultVisualization = (
          <ContainerDimensions>
            {
              ({ width, height }) => <DataclipVisualization height={height} width={width} {...visualizationProps} />
            }
          </ContainerDimensions>
        )
        showChart = true
      }
    }

    let resultStats, lastRan

    if (resultMetadata) {
      const [rows, rowsSuffix] = numberPrettifier(resultData.getIn(['data', 'values']).size)
      resultStats = `${rows}${rowsSuffix} rows`

      const queryStartedAt = resultMetadata.get('query_started_at')
      const queryFinishedAt = resultMetadata.get('query_finished_at')

      if (queryStartedAt && queryFinishedAt) {
        const delta = moment(queryFinishedAt).diff(moment(queryStartedAt))
        const [duration, durationSuffix] = durationPrettifier(delta)
        resultStats += ` in ${duration} ${durationSuffix}`
      }

      lastRan = moment(latestResultAt).format('MMMM D, YYYY HH:mm')
    } else {
      resultStats = <Spinner className='mt2' />
      lastRan = <Spinner className='mt2 self-start' />
    }

    const chartButton = <HKButton disabled={!showChart} className={classnames('br--right nudge-left--1', {normal: resultsTab !== 'chart', 'light-purple': (resultsTab !== 'chart') && showChart})} title='chart' onClick={this.handleTabNavigation}>Chart</HKButton>
    const tooltipContent = (
      <div className='tc'>
        <p className='mv0'>Between 2-1000 rows of time series data</p>
        <p className='mv0'>are needed to show a chart</p>
      </div>
    )

    let chartButtonBlock = !showChart
      ? <Tooltip content={tooltipContent}>{chartButton}</Tooltip>
      : chartButton

    return (
      <React.Fragment>
        <div className='flex-l items-center justify-between mh4 mt4 bg-white'>
          <div className='flex mb2 mb0-l'>
            <div className='mr3'>
              <div className='gray ttu tracked f7 mt0 mb1'>Results</div>
              <div className='mv0'>{resultStats}</div>
            </div>
            <div>
              <div className='gray ttu tracked f7 mt0 mb1'>Updated</div>
              <div className='mv0'>{lastRan}</div>
            </div>
          </div>
          {
            hasNewResults && (
              <HKButton className='mb2 mb0-l' type='success' onClick={onRefreshResults}>Load New Results</HKButton>
            )
          }
          <div className='flex items-center'>
            <div className='flex nowrap'>
              <DataclipSharingDropdown editable={editable} clipSlug={clipSlug} accessToken={accessToken} />
              <HKButton className={classnames('br--left nudge-right--1 z-1', {normal: resultsTab !== 'data', 'light-purple': resultsTab !== 'data'})} title='data' onClick={this.handleTabNavigation}>Data</HKButton>
              {chartButtonBlock}
            </div>
          </div>
        </div>
        <div className={classnames('flex-auto dataclip-result-wrap ma4', `dataclipResult-${resultsTab}`)}>
          {resultsTab === 'chart' ? resultVisualization : resultTable }
        </div>
      </React.Fragment>
    )
  }
}
