import React, { Component } from 'react'
import { MalibuIcon } from '@heroku/react-malibu'
import DocumentTitle from 'react-document-title'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import Gravatar from 'react-gravatar'
import RelativeTime from 'client/components/RelativeTime'

import Spinner from 'client/components/Spinner'
import { HKButton } from '@heroku/react-hk-components'
export default class DataclipHeader extends Component {
  static propTypes = {
    author: PropTypes.instanceOf(Immutable.Map),
    created: PropTypes.string,
    isPublic: PropTypes.bool,
    loading: PropTypes.bool,
    onShareClick: PropTypes.func,
    showShareButton: PropTypes.bool,
    title: PropTypes.string,
  }

  render () {
    const { author, created, title, loading, showShareButton, isPublic } = this.props
    const documentTitle = loading ? 'Dataclips | Heroku Data' : `${title || 'Create a Dataclip'} | Heroku Data`
    const lastItemContent = loading ? <Spinner /> : title
    const authorEmail = author && author.get('email')

    return (<DocumentTitle title={documentTitle}>
      <header className='bg-lightest-silver f3 w-100 pa3 dark-gray bb b--silver'>
        <div className='flex items-center justify-between flex-wrap'>
          <div className='flex items-center w-100 w-50-l'>
            <div id='dataclips' className='flex items-center'>
              <MalibuIcon name='data-28' size={28} fillClass='purple' extraClasses='mr1' />
              { isPublic ? (<div>Dataclips</div>) : (<Link to='/dataclips' className='link normal dark-gray'>Dataclips</Link>)}
            </div>
            <MalibuIcon name='nav-forward-16' size={16} fillClass='fill-gray' extraClasses='mh1' />
            <div className='flex items-center'>
              <MalibuIcon name='dataclip-28' size={20} fillClass='purple' extraClasses='mr1' />
              <div className='dark-gray normal flex-auto'>{lastItemContent}</div>
            </div>
          </div>
          <div className='flex-l items-center justify-end-l flex-wrap w-100 w-50-l'>
            <div className='flex flex-wrap'>
              {author && (
                <div className='w-100 w-auto-l mt3 mt0-l'>
                  <p className='gray ttu tracked f7 mt0 mb1'>Author</p>
                  <div className='flex items-center'>
                    <Gravatar email={authorEmail} className='gravatar br-100 mr1' size={16} rating='pg' default='mm' />
                    <Link
                      className='link'
                      to={{
                        pathname: '/dataclips',
                        search: `?query=${authorEmail}`
                      }}>
                      <span className='f5'>{authorEmail}</span>
                    </Link>
                  </div>
                </div>
              )}
              {created && (
                <div className='w-100 w-auto-l mt3 mt0-l ml6-l'>
                  <p className='gray ttu tracked f7 mt0 mb1'>Created</p>
                  <div className='flex items-center'>
                    <span className='f5'>
                      <RelativeTime date={created} />
                    </span>
                  </div>
                </div>
              )}
            </div>
            {showShareButton && (
              <div className='mt3 mt0-l ml6-l'>
                <HKButton onClick={this.props.onShareClick} small={true} type='primary'>
                  <MalibuIcon name='team-16' size={14} fillClass='fill-white' extraClasses='mr1' />
                  Share
                </HKButton>
              </div>
            )}
          </div>
        </div>
      </header>
    </DocumentTitle>)
  }
}
