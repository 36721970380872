import moment from 'moment'

// Parses an interval of the form '10m' into the number of
// milliseconds the interval represents
export default function parseInterval (interval) {
  let match = interval.match(/(\d+)(ms|s|m|h|d)/)
  if (!match) {
    throw new Error(`Invalid interval: ${interval}`)
  }

  return moment.duration(parseInt(match[1], 10), match[2])
    .asMilliseconds()
}
